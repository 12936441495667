import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import MobileSideBar from './MobileSideBar';
import quick_send_icon from '../../../../assets/images/quick_send_icon.svg';

const Sidebar = styled.div`
  position: sticky;
  top: 4rem;
  overflow-y: scroll;
  padding-right: 1rem;
  height: calc(100vh - 7rem);
  width: 200px;
  border: none;
  font-size: 14px;
  font-weight: 300;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e5e1e1;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    width: 100vw;
    padding-right: 0;
    height: fit-content;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

const AppIcon = styled.img`
  width: 60px;
  margin-bottom: 2rem;
`;

const MenuItem = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

interface SideBarProps {
  scrollToElement: any;
}

const SideBar = ({ scrollToElement }: SideBarProps) => {
  const [activePage, setActivePage] = useState<any>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleMenuClick = (event: any, element: string) => {
    scrollToElement(element);
    if (!event.target.classList.value.includes('active')) {
      event.target.classList.toggle('active');
      if (activePage) activePage.classList.remove('active');
      setActivePage(event.target);
    }
  };

  return (
    <Sidebar>
      {isMobile ? (
        <MobileSideBar handleMenuClick={handleMenuClick} />
      ) : (
        <Menu>
          <AppIcon src={quick_send_icon} />
          <MenuItem
            className="menu-item"
            onClick={(event) => {
              handleMenuClick(event, 'installation');
            }}
          >
            Installation
          </MenuItem>
          <MenuItem
            className="menu-item"
            onClick={(event) => {
              handleMenuClick(event, 'get-started');
            }}
          >
            Get started
          </MenuItem>
        </Menu>
      )}
    </Sidebar>
  );
};

export default SideBar;
