import styled from 'styled-components';
import SideBar from './SideBar';
import { Element, scroller } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import Layout from '../../../../shared/components/Layout/Layout';
import quicksend_1 from '../../../../assets/images/quicksend_1.svg';
import quicksend_2 from '../../../../assets/images/quicksend_2.svg';
import quicksend_3 from '../../../../assets/images/quicksend_3.svg';
import quicksend_4 from '../../../../assets/images/quicksend_4.svg';

const Container = styled.div`
  display: flex;
  margin: 2rem 5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

const SidebarSection = styled.div`
  width: 210px;
  @media (max-width: 768px) {
    width: unset;
    position: sticky;
    top: 80px;
    background-color: #ebeae6;
    border-bottom: 1px solid #ebeae6;
    backdrop-filter: blur(8px);
  }
`;

const Content = styled.div`
  width: calc(100% - 210px);
  padding: 0 5rem;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const Header = styled.div`
  padding-bottom: 3rem;
  border-bottom: 1px solid rgb(194, 194, 194);
  @media (max-width: 768px) {
    margin: 1.5rem;
  }
`;

const Hero = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const HeroTitle = styled.div`
  font-weight: 600;
  font-size: 32px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgb(194, 194, 194);
  &:last-of-type {
    border-bottom: none;
  }
  @media (max-width: 768px) {
    margin: 1.5rem;
  }
`;

const GetStatrted = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Text = styled.div`
  font-weight: 300;
  text-align: justify;
  line-height: 22px;
  padding-bottom: 1rem;
`;

interface ImageProps {
  width?: number;
}

const Image = styled.img<ImageProps>`
  width: ${(props) => (props.width ? `${props.width}%` : '65%')};
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  margin: 1.5rem auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const QuickSendHowToUseSite = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const scrollToElement = (element: string) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: isMobile ? -310 : -100,
    });
  };
  return (
    <Layout>
      <Container>
        <SidebarSection>
          <SideBar scrollToElement={scrollToElement} />
        </SidebarSection>
        <Content>
          <Header>
            <Hero>
              <HeroTitle>How To Use</HeroTitle>
            </Hero>
            <Text>
              Quick Send lets users send direct messages to popular messaging
              apps directly from any phone column without needing to save
              contacts! Simply select a phone column, write your message,
              personalize it with fields from your board, and send it in a snap!
            </Text>
            <Text>
              This document provides instructions on utilizing the application
              and outlines its features in detail.
            </Text>
          </Header>
          <Section>
            <Element name="installation">
              <Title>Installation</Title>
              <Text>
                1. Go to monday.com app marketplace or click the button below:
              </Text>
              <Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://auth.monday.com/oauth2/authorize?client_id=0cba36c781feedf4e386f4747b04a7f7&response_type=install"
                >
                  <img
                    alt="Add to monday.com"
                    height="42"
                    src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                  />
                </a>
              </Text>
              <Text>
                2. Search for <b>"Quick Send"</b>
              </Text>
              <Text>
                3. Click <b>"Add to your account"</b> button
              </Text>
              <Text>
                4. Open Quick Send app on any item as board item menu.
              </Text>
              <GetStatrted>
                <Image src={quicksend_1} width={80} alt="quicksend_1" />
              </GetStatrted>
            </Element>
          </Section>
          <Section>
            <Element name="get-started">
              <GetStatrted>
                <Title>Get started</Title>
                <Text>
                  Quick Send allows users to send direct messages from any phone
                  column without saving contacts!
                </Text>
                <Subtitle>Select phone column</Subtitle>
                <Text>
                  Start by selecting a phone column from your board. The phone
                  number will appear on the right, indicating whether it is
                  valid or not. This selection will be saved for the next time
                  you use the app.
                </Text>
                <Image src={quicksend_2} width={90} alt="quicksend_2" />
                <Subtitle>Write your message</Subtitle>
                <Text>
                  The next step is to write your message. To save time and
                  improve efficiency, you can create and save a template
                  message, personalized with fields from your board.
                </Text>
                <Image src={quicksend_3} width={90} alt="quicksend_3" />
                <Subtitle>Choose a messaging app</Subtitle>
                <Text>
                  The final step is to choose the messaging app you'd like to
                  use to send the message. Currently, you can select from three
                  popular options: WhatsApp, Telegram, and iMessage. Once you
                  click on an app, a chat window will open with the selected
                  phone number, and the message will be pre-filled, ready for
                  sending.
                </Text>
                <Image src={quicksend_4} width={90} alt="quicksend_4" />
              </GetStatrted>
            </Element>
          </Section>
        </Content>
      </Container>
    </Layout>
  );
};

export default QuickSendHowToUseSite;
