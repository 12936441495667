export enum RoutePaths {
  HOME = '/',
  MONDAY = '/monday',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_OF_SERVICE = '/terms-of-service',
  GENERAL_HOW_TO_USE = '/in-app/how-to-use',
  LOG_BACK_HOW_TO_USE_SITE = '/log-back/how-to-use',
  LOG_BACK_HOW_TO_USE_APP = '/log-back/app/how-to-use',
  TEXT_WIZ_HOW_TO_USE_SITE = '/text-wiz/how-to-use',
  TEXT_WIZ_HOW_TO_USE_APP = '/text-wiz/app/how-to-use',
  MASTERPLAN_CALENDAR_HOW_TO_USE_SITE = '/masterplan-calendar/how-to-use',
  MASTERPLAN_CALENDAR_HOW_TO_USE_APP = '/masterplan-calendar/app/how-to-use',
  MULTI_EMBED_HOW_TO_USE_SITE = '/multi-embed/how-to-use',
  MULTI_EMBED_HOW_TO_USE_APP = '/multi-embed/app/how-to-use',
  QUICK_SEND_HOW_TO_USE_SITE = '/quick-send/how-to-use',
  QUICK_SEND_HOW_TO_USE_APP = '/quick-send/app/how-to-use',
  MONDAY_APP_ASSOCIATION = '/monday-app-association.json',
}
