import styled from 'styled-components';
import app_solution_logo from '../../../assets/images/app_solution_logo.svg';
import { Link, NavLink } from 'react-router-dom';
import { RoutePaths } from '../../enums/RoutePaths';
import { useMediaQuery } from 'react-responsive';
import Burger from '../Burger/Burger';
import { useState } from 'react';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100vw;
  height: 5rem;
  padding: 1rem 5rem;
  box-sizing: border-box;
  background-color: #ffffffc9;
  box-shadow: rgba(0, 0, 0, 0.075) 0 1px;
  backdrop-filter: blur(10px);
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Logo = styled.div`
  background-image: url(${app_solution_logo});
  background-repeat: no-repeat;
  background-size: contain;
  width: 162px;
  height: 34px;
  @media (max-width: 768px) {
    width: 162px;
    height: 34px;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SingleLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  margin: 0 1rem;
  font-weight: 300;
  s &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const MobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc9;
  backdrop-filter: blur(10px);
  z-index: 1;
`;

const MobileSingleLink = styled(NavLink)`
  color: inherit;
  font-size: 18px;
  text-decoration: none;
  width: fit-content;
  margin: 1rem;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Navbar = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [open, setOpen] = useState(false);

  return (
    <>
      <Container>
        <Link to={RoutePaths.HOME}>
          <Logo />
        </Link>
        {isMobile ? (
          <Burger open={open} setOpen={setOpen} />
        ) : (
          <Links>
            <SingleLink to={RoutePaths.LOG_BACK_HOW_TO_USE_SITE}>
              LogBack
            </SingleLink>
            <SingleLink to={RoutePaths.QUICK_SEND_HOW_TO_USE_SITE}>
              Quick Send
            </SingleLink>
            <SingleLink to={RoutePaths.MASTERPLAN_CALENDAR_HOW_TO_USE_SITE}>
              MasterPlan Calendar
            </SingleLink>
            <SingleLink to={RoutePaths.MULTI_EMBED_HOW_TO_USE_SITE}>
              Multi Embed
            </SingleLink>
            <SingleLink to={RoutePaths.TEXT_WIZ_HOW_TO_USE_SITE}>
              TextWiz
            </SingleLink>
            <SingleLink to={RoutePaths.MONDAY}>monday.com</SingleLink>
          </Links>
        )}
      </Container>
      {open && (
        <MobileLinks>
          <MobileSingleLink to={RoutePaths.LOG_BACK_HOW_TO_USE_SITE}>
            LogBack
          </MobileSingleLink>
          <MobileSingleLink to={RoutePaths.QUICK_SEND_HOW_TO_USE_SITE}>
            Quick Send
          </MobileSingleLink>
          <MobileSingleLink to={RoutePaths.MASTERPLAN_CALENDAR_HOW_TO_USE_SITE}>
            MasterPlan Calendar
          </MobileSingleLink>
          <MobileSingleLink to={RoutePaths.MULTI_EMBED_HOW_TO_USE_SITE}>
            Multi Embed
          </MobileSingleLink>
          <MobileSingleLink to={RoutePaths.TEXT_WIZ_HOW_TO_USE_SITE}>
            TextWiz
          </MobileSingleLink>
          <MobileSingleLink to={RoutePaths.MONDAY}>monday.com</MobileSingleLink>
        </MobileLinks>
      )}
    </>
  );
};

export default Navbar;
