import styled from 'styled-components';
import { Route, Routes } from 'react-router-dom';
import { RoutePaths } from '../shared/enums/RoutePaths';
import Home from '../pages/Home/Home';
import Monday from '../pages/Monday/Monday';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService/TermsOfService';
import LogBackHowToUseSite from '../pages/HowToUse/LogBack/site/LogBackHowToUseSite';
import LogBackHowToUseApp from '../pages/HowToUse/LogBack/app/LogBackHowToUseApp';
import TextWizHowToUseSite from '../pages/HowToUse/TextWiz/site/TextWizHowToUseSite';
import TextWizHowToUseApp from '../pages/HowToUse/TextWiz/app/TextWizHowToUseApp';
import MondayAppAssociation from '../pages/MondayAppAssociation/MondayAppAssociation';
import MasterPlanHowToUseSite from '../pages/HowToUse/MasterPlan/site/MasterPlanHowToUseSite';
import MasterPlanHowToUseApp from '../pages/HowToUse/MasterPlan/app/MasterPlanHowToUseApp';
import MultiEmbedHowToUseApp from '../pages/HowToUse/MultiEmbed/app/MultiEmbedHowToUseApp';
import MultiEmbedHowToUseSite from '../pages/HowToUse/MultiEmbed/site/MultiEmbedHowToUseSite';
import QuickSendHowToUseApp from '../pages/HowToUse/QuickSend/app/QuickSendHowToUseApp';
import QuickSendHowToUseSite from '../pages/HowToUse/QuickSend/site/QuickSendHowToUseSite';

const App = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
`;

const AppSolution = () => {
  return (
    <App>
      <Routes>
        <Route path={RoutePaths.HOME} element={<Home />} />
        <Route
          path={RoutePaths.GENERAL_HOW_TO_USE}
          element={<LogBackHowToUseApp />}
        />
        <Route
          path={RoutePaths.LOG_BACK_HOW_TO_USE_SITE}
          element={<LogBackHowToUseSite />}
        />
        <Route
          path={RoutePaths.LOG_BACK_HOW_TO_USE_APP}
          element={<LogBackHowToUseApp />}
        />
        <Route
          path={RoutePaths.MASTERPLAN_CALENDAR_HOW_TO_USE_SITE}
          element={<MasterPlanHowToUseSite />}
        />
        <Route
          path={RoutePaths.MULTI_EMBED_HOW_TO_USE_APP}
          element={<MultiEmbedHowToUseApp />}
        />
        <Route
          path={RoutePaths.MULTI_EMBED_HOW_TO_USE_SITE}
          element={<MultiEmbedHowToUseSite />}
        />
        <Route
          path={RoutePaths.QUICK_SEND_HOW_TO_USE_APP}
          element={<QuickSendHowToUseApp />}
        />
        <Route
          path={RoutePaths.QUICK_SEND_HOW_TO_USE_SITE}
          element={<QuickSendHowToUseSite />}
        />
        <Route
          path={RoutePaths.MASTERPLAN_CALENDAR_HOW_TO_USE_APP}
          element={<MasterPlanHowToUseApp />}
        />
        <Route
          path={RoutePaths.TEXT_WIZ_HOW_TO_USE_SITE}
          element={<TextWizHowToUseSite />}
        />
        <Route
          path={RoutePaths.TEXT_WIZ_HOW_TO_USE_APP}
          element={<TextWizHowToUseApp />}
        />
        <Route path={RoutePaths.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route
          path={RoutePaths.TERMS_OF_SERVICE}
          element={<TermsOfService />}
        />
        <Route path={RoutePaths.MONDAY} element={<Monday />} />
        <Route
          path={RoutePaths.MONDAY_APP_ASSOCIATION}
          element={<MondayAppAssociation />}
        />
      </Routes>
    </App>
  );
};

export default AppSolution;
