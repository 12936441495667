import styled from 'styled-components';
import Layout from '../../shared/components/Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../shared/enums/RoutePaths';
import monday_logo from '../../assets/images/monday_logo.svg';
import masterplan_logo_icon from '../../assets/images/masterplan_logo_icon.svg';
import masterplan_app_image from '../../assets/images/masterplan_app_image.svg';
import log_back_icon from '../../assets/images/log_back_icon.svg';
import logback_app_image from '../../assets/images/logback_app_image.svg';
import multi_embed_icon from '../../assets/images/multi_embed_icon.svg';
import multi_embed_app_image from '../../assets/images/multi_embed_app_image.svg';
import quick_send_icon from '../../assets/images/quick_send_icon.svg';
import quick_send_app_image from '../../assets/images/quick_send_app_image.svg';
import textwiz_logo from '../../assets/images/textwiz_logo.svg';
import textwiz_app_image from '../../assets/images/textwiz_app_image.svg';

const Container = styled.div`
  width: 60%;
  margin: 5rem auto;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
`;

const AppContent = styled.div`
  display: flex;
  margin-top: 3rem;
  margin-bottom: 6rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1px;
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.3px;
`;

const MondayLogo = styled.img`
  width: 40px;
  height: 100%;
  margin-left: 1rem;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 0.6rem;
  }
`;

const AppSection = styled.div``;

const AppHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AppIcon = styled.img`
  width: 60px;
  margin: 1rem 0;
`;

const Text = styled.div`
  text-align: center;
  line-height: 34px;
  padding-bottom: 1rem;
`;

const AppImage = styled.img`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 2rem;
  }
`;

const AppName = styled.span`
  color: #d5b343;
  font-weight: 600;
`;

const Content = styled.div`
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const Button = styled.div`
  font-weight: 600;
  padding-top: 1rem;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Monday = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Container>
        <Section>
          <SectionHeader>
            <Title>About monday.com</Title>
            <MondayLogo src={monday_logo} />
          </SectionHeader>
          <Text>
            monday.com is a versatile and intuitive project management and team
            collaboration platform that empowers individuals and organizations
            to streamline their workflows and achieve greater productivity. It
            provides a visual and customizable workspace where teams can plan,
            track, and manage their tasks, projects, and deadlines with ease.
          </Text>
        </Section>
        <Section>
          <SectionHeader>
            <Title>Our applications for monday.com</Title>
          </SectionHeader>
          <AppSection>
            <AppHeader>
              <Subtitle>Quick Send</Subtitle>
              <AppIcon src={quick_send_icon} />
            </AppHeader>
            <Text>
              <AppName>Quick Send</AppName> lets users send direct messages to
              popular messaging apps directly from any phone column without
              needing to save contacts!
            </Text>
            <AppContent>
              <AppImage src={quick_send_app_image} />
              <Content>
                <Text>
                  Quick Send helps you streamline communication and save time.
                  Just select a phone column, craft your message, personalize it
                  with fields from your board, and send it instantly!
                </Text>
                <Button
                  onClick={() =>
                    navigate(RoutePaths.QUICK_SEND_HOW_TO_USE_SITE)
                  }
                >
                  Learn more
                </Button>
                <Button>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://auth.monday.com/oauth2/authorize?client_id=0cba36c781feedf4e386f4747b04a7f7&response_type=install"
                  >
                    <img
                      alt="Add to monday.com"
                      height="42"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                    />
                  </a>
                </Button>
              </Content>
            </AppContent>
          </AppSection>
          <AppSection>
            <AppHeader>
              <Subtitle>Multi Embed</Subtitle>
              <AppIcon src={multi_embed_icon} />
            </AppHeader>
            <Text>
              With <AppName>Multi Embed</AppName> you can view all your embedded
              links in a single place. When all your related embedded links are
              together, you get a bird's-eye view of everything you need.
            </Text>
            <AppContent>
              <AppImage src={multi_embed_app_image} />
              <Content>
                <Text>
                  Goodbye Context Switching! Embed multiple tool URLs in one
                  place! Drag & Drop, Resize & position them as you want. Your
                  all-in-one workflow powerhouse.
                </Text>
                <Button
                  onClick={() =>
                    navigate(RoutePaths.MULTI_EMBED_HOW_TO_USE_SITE)
                  }
                >
                  Learn more
                </Button>
                <Button>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://auth.monday.com/oauth2/authorize?client_id=e14d4298aee9e22804146a06aa4364b7&response_type=install"
                  >
                    <img
                      alt="Add to monday.com"
                      height="42"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                    />
                  </a>
                </Button>
              </Content>
            </AppContent>
          </AppSection>
          <AppSection>
            <AppHeader>
              <Subtitle>MasterPlan Calendar</Subtitle>
              <AppIcon src={masterplan_logo_icon} />
            </AppHeader>
            <Text>
              Introducing <AppName>MasterPlan Calendar</AppName>, the essential
              monday.com app for efficient events scheduling. Easily visualize
              items as events, complete with start and end times. Drag, drop,
              and resize with simplicity, optimizing your workflow like never
              before. We have
            </Text>
            <AppContent>
              <AppImage src={masterplan_app_image} />
              <Content>
                <Text>
                  Ensure seamless project management by effortlessly visualizing
                  events and their timelines with{' '}
                  <AppName>MasterPlan Calendar</AppName>.
                </Text>
                <Button
                  onClick={() =>
                    navigate(RoutePaths.MASTERPLAN_CALENDAR_HOW_TO_USE_SITE)
                  }
                >
                  Learn more
                </Button>
                <Button>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://auth.monday.com/oauth2/authorize?client_id=683a850898635b63ab88424554eecdec&response_type=install"
                  >
                    <img
                      alt="Add to monday.com"
                      height="42"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                    />
                  </a>
                </Button>
              </Content>
            </AppContent>
          </AppSection>
          <AppSection>
            <AppHeader>
              <Subtitle>LogBack</Subtitle>
              <AppIcon src={log_back_icon} />
            </AppHeader>
            <Text>
              We have developed <AppName>LogBack</AppName>, the ultimate tool
              for monday marketplace which help to take control on your board's
              activities. It provides you with the option to effortlessly undo
              the majority of actions performed on the your boards.
            </Text>
            <AppContent>
              <AppImage src={logback_app_image} />
              <Content>
                <Text>
                  With <AppName>LogBack</AppName> you can effectively prevents
                  data loss and human errors by allowing you to undo most the
                  the board's activity logs.
                </Text>
                <Button
                  onClick={() => navigate(RoutePaths.LOG_BACK_HOW_TO_USE_SITE)}
                >
                  Learn more
                </Button>
                <Button>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://auth.monday.com/oauth2/authorize?client_id=15a5df7d55da9464b68113a464c56682&response_type=install"
                  >
                    <img
                      alt="Add to monday.com"
                      height="42"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                    />
                  </a>
                </Button>
              </Content>
            </AppContent>
          </AppSection>
          <AppSection>
            <AppHeader>
              <Subtitle>TextWiz</Subtitle>
              <AppIcon src={textwiz_logo} />
            </AppHeader>
            <Text>
              We've also created an app known as <AppName>TextWiz</AppName>,
              which functions as a text column manipulation tool within item
              view applications, featuring advanced AI capabilities.
            </Text>
            <AppContent>
              <AppImage src={textwiz_app_image} />
              <Content>
                <Text>
                  <AppName>TextWiz</AppName> empowers users with advanced AI
                  capabilities to translate, summarize, rephrase, and even
                  proofread text within columns or long text associated with
                  your items
                </Text>
                <Button
                  onClick={() => navigate(RoutePaths.TEXT_WIZ_HOW_TO_USE_SITE)}
                >
                  Learn more
                </Button>
                <Button>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://auth.monday.com/oauth2/authorize?client_id=7613b98cf8981444d6a479ea384b3ad1&response_type=install"
                  >
                    <img
                      alt="Add to monday.com"
                      height="42"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                    />
                  </a>
                </Button>
              </Content>
            </AppContent>
          </AppSection>
        </Section>
      </Container>
    </Layout>
  );
};

export default Monday;
